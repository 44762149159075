// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import Select, { components } from 'react-select';
import { Icon } from '@/core/Icon.atom';
import { useTranslation } from 'react-i18next';

export interface CheckBoxSelectOption {
  text: string;
  value: any;
}

export interface CheckboxSelectProps {
  selectOptions: CheckBoxSelectOption[];
  onChange: (option: CheckBoxSelectOption, property?: string) => void;
  placeholder?: string;
  value?: any;
  testId?: string;
  insideModal?: boolean;
  isMultipleSelect?: boolean; // radio buttons are used by default, checkboxes will be used if this is true
  selectClasses?: string;
  wrapperClasses?: string;
  label: string;
}

/**
 * Multi-select dropdown with item checkboxes
 */
export const CheckboxSelect: React.FunctionComponent<CheckboxSelectProps> = (props) => {
  const {
    testId,
    value,
    label,
    selectOptions,
    placeholder,
    onChange,
    insideModal,
    isMultipleSelect,
    selectClasses,
    wrapperClasses,
  } = props;

  const { t } = useTranslation();

  const menuPosition = insideModal ? 'absolute' : 'fixed';
  const portalStyle = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) };

  const makeLabel = (option: CheckBoxSelectOption) => {
    const isSelectedValue = _.find(value, (val) => _.isEqual(val, option.value));

    const icon = (isSelected: boolean) => {
      if (isMultipleSelect) {
        return isSelected ? 'fa-check-square' : 'fa-square-o';
      } else {
        return isSelected ? 'fa-check-circle' : 'fa-circle-thin';
      }
    };

    return (
      <span className="flexColumnContainer flexAlignCenter">
        <span className="flexColumnContainer flexAlignCenter">
          <Icon
            large={true}
            icon={icon(isSelectedValue)}
            type={isMultipleSelect && isSelectedValue ? 'theme' : 'text'}
          />
          <span className="ml10">{t(option.text)}</span>
        </span>
      </span>
    );
  };

  const options = _.map(selectOptions, (option) => ({
    ...option,
    label: makeLabel(option),
  }));
  const selectedValues = _.chain(value)
    .map((value) => _.find(options, { value }))
    .compact()
    .value();
  const selectedOptions = _.map(selectedValues, (value) => t(value.text));

  // This enables a different label to display for the selected item once the menu is closed
  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      <span>
        {selectedOptions.length === 0 || _.isUndefined(selectedOptions) ? (
          <span>{t(label)}</span>
        ) : (
          <span>{_.join(selectedOptions, ', ')}</span>
        )}
      </span>
    </components.SingleValue>
  );

  return (
    <div data-testid={testId} className={wrapperClasses}>
      <Select
        className={selectClasses}
        classNamePrefix={`${selectClasses} react-select`}
        placeholder={_.isEmpty(placeholder) ? '' : t(placeholder)}
        value={selectedValues}
        menuPosition={menuPosition}
        styles={insideModal && portalStyle}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        options={options}
        isSearchable={false}
        closeMenuOnSelect={!isMultipleSelect}
        components={{ SingleValue }}
        onChange={(selectedOption) => onChange(selectedOption)}
      />
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FormControl, InputGroup } from 'react-bootstrap';
import { TextButton } from '@/core/TextButton.atom';
import { Icon } from '@/core/Icon.atom';

interface ClearableInputProps {
  /** identifier, provided to the filterTable function as the first parameter */
  field: string;
  /** text displayed in the input field */
  searchValue: string;
  /** called whenever the text in the input field changes */
  onInputChange: (field: string, value: string) => void;
  /** use a custom icon instead of checkmark */
  iconClassName?: string;
  /** true if the input box should be greyed out and disabled */
  disabled?: boolean;
  /** if provided, a checkmark or custom icon is displayed in the box, and clicking
   it or pressing enter will trigger the callback */
  enterCallback?: () => void;
  /** if provided, trigger the callback if input lose focus */
  onBlurCallback?: () => void;
  /** if provided, determined if the input field will be automatically focused on */
  autoFocus?: boolean;
}

/**
 * This component renders a text input field that displays a "delete" icon in the far right once text has been entered.
 */
export const ClearableInput: React.FunctionComponent<ClearableInputProps> = ({
  field,
  onInputChange,
  searchValue,
  disabled,
  iconClassName = 'fa-check',
  enterCallback,
  onBlurCallback,
  autoFocus,
}) => {
  const { t } = useTranslation();

  return (
    <InputGroup className="flexColumnContainer flexNoWrap">
      <FormControl
        className="width-50"
        disabled={disabled}
        data-testid={`clearableInput_${field}`}
        value={searchValue ?? ''}
        type="text"
        placeholder={t('ADMIN.FILTER_BY')}
        onKeyDown={(e: React.KeyboardEvent) => e.key === 'Enter' && enterCallback?.()}
        onChange={({ target: { value } }) => onInputChange(field, value)}
        onBlur={() => onBlurCallback?.()}
        autoFocus={autoFocus}
      />
      <InputGroup.Append className="height-34 p-0">
        {enterCallback && (
          <InputGroup.Text className="height-34 p-0 border-0">
            <TextButton
              testId={`enterBtn_${field}`}
              extraClassNames="height-34"
              formattedLabel={<Icon icon={iconClassName} type="text" />}
              onClick={enterCallback}
            />
          </InputGroup.Text>
        )}

        {searchValue && (
          <InputGroup.Text className="height-34 p-0 border-0">
            <TextButton
              testId="clearBtn"
              extraClassNames="height-34"
              formattedLabel={<Icon icon="fa-times" type="text" />}
              onClick={() => {
                onInputChange(field, '');
                enterCallback?.();
              }}
            />
          </InputGroup.Text>
        )}
      </InputGroup.Append>
    </InputGroup>
  );
};
